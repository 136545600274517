<template>
    <div class="bg-[#030325] w-full flex justify-center py-10">
        <div class="mx-5 lg:max-w-6xl w-full">
            <div class="flex flex-col justify-start md:flex-row md:justify-between md:items-center mb-8">
                <a href="#main" class="mb-5 -ml-2 md:ml-0">
                    <div class="h-full flex flex-row justify-between items-center">
                        <img v-motion-pop-visible src="../assets/logo_name.png" class="w-auto h-[45px] md:h-[50px]" alt="logo">
                    </div>
                </a>
                <div v-motion-pop-visible class="-ml-2 mb-8 flex flex-col justify-start md:ml-0 md:flex-row md:items-center gap-[15px] lg:mb-0 lg:flex-row md:justify-evenly text-white whitespace-nowrap">
                    <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" role="menuitem" :class="['block w-full text-left px-1 md:px-6 py-2 rounded mb-2 transition duration-200', { 'transition-colors bg-clip-text text-transparent bg-gradient-to-r from-yellow-800  to-yellow-500 font-medium': activeSection === item.id }]">{{ item.name }}</a>
                </div>
                <div v-motion-pop-visible class="flex space-x-4 -ml-2 md:ml-0 md:justify-center items-center">
                    <a href="https://www.youtube.com/@GravyBets" class="bg-[#0b0c4c] p-2.5 rounded-full" target="_blank">
                        <img v-motion-pop-visible src="../assets/icon_youtube.png" class="w-5 h-5" alt="logo">
                    </a>
                    <a href="https://discord.gg/EPQh3rPhme" class="bg-[#0b0c4c] p-2.5 rounded-full" target="_blank">
                        <img v-motion-pop-visible src="../assets/icon_discord.png" class="w-5 h-5" alt="logo">
                    </a>
                    <a href=" https://www.instagram.com/gravy_bets/" class="bg-[#0b0c4c] p-2.5 rounded-full" target="_blank">
                        <img v-motion-pop-visible src="../assets/icon_instagram.png" class="w-5 h-5" alt="logo">
                    </a>
                    <a href="https://www.tiktok.com/@gravy_bets" class="bg-[#0b0c4c] p-2.5 rounded-full" target="_blank">
                        <img v-motion-pop-visible src="../assets/icon_tiktok.png" class="w-5 h-5" alt="logo">
                    </a>
                    <a href="https://x.com/GravyBet" class="bg-[#0b0c4c] p-2.5 rounded-full" target="_blank">
                        <img v-motion-pop-visible src="../assets/icon_twitter.png" class="w-5 h-5" alt="logo">
                    </a>
                </div>
            </div>
            <div>
                <p class="text-gray-400 font-normal text-sm leading-6 tracking-wide">
                    If you ever feel that gambling is becoming a problem. Most people gamble for fun and enjoyment. However, some people think of gambling as a way to make money, spend more than they can afford, or use gambling to distract themselves from everyday problems.
                </p>
                <p class="text-gray-400 font-normal text-sm leading-6 tracking-wide py-4">
                    To find further advice, help and support visit: <a href="https://www.begambleaware.org" class="text-yellow-600 underline">https://www.begambleaware.org/</a>
                </p>
                <p class="text-gray-400 font-normal text-sm leading-6 tracking-wide">
                    We do not take responsibility for misunderstood promotions, losses from gambling in casinos or betting on sites which are linked to or promoted on this website. Make sure it is legal for you to use each site inside your jurisdiction and ALWAYS gamble responsibly!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
    props: {
        activeSection: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            navItems: [
                { id: 'home', name: 'Home' },
                { id: 'socials', name: 'Socials' },
                { id: 'leaderboards', name: 'Leaderboards' },
            ]
        };
    },
}
</script>