<template>
  <div class="min-h-screen h-full mx-auto overflow-hidden main-bg">
    <section id="home" class="relative" v-motion-pop-visible>
      <img
        src="../assets/logo_name.png"
        alt="bg-logoname"
        class="opacity-5 scale-[1.4] md:scale-[1.2] fixed top-[68%] left-1/2 -translate-x-1/2 -translate-y-1/2 md:max-w-full h-auto"
      />
      <img
        src="../assets/x-black.png"
        alt="Image 1"
        class="object-cover bg-cover bg-center fixed top-[55%] translate-x-2.5 translate-y-5 rotate-[9deg] md:rotate-[3deg] scale-[3] md:scale-[1.2] z-5"
      />
      <img
        src="../assets/x-white.png"
        alt="Image 2"
        class="object-cover bg-cover bg-center fixed top-[58%] translate-x-2.5 translate-y-5 rotate-[-9deg] md:-rotate-[9deg] scale-[3] md:scale-[1.2] z-5"
      />
      <div
        class="flex items-center justify-center object-cover bg-cover bg-center bg-no-repeat top-[70%] md:top-[16.5%] left-1/2 -translate-x-1/2"
      ></div>
      <div class="">
        <div class="min-h-screen flex items-center justify-center section_main">
          <div class="relative max-w-2xl md:max-w-6xl mx-auto md:-mt-0">
            <img
              v-motion-slide-visible-top
              class="w-full mt-[24px] max-w-[450px] md:max-[420px] lg:max-w-[450px] lg:max-h-[400px] xl:max-w-[500px] xl:max-h-[470px] 2xl:max-w-[590px] 2xl:max-h-[570px]"
              src="../assets/unleashed_v2.png"
              alt="gravybets"
            />
            <a href="https://roobet.com/?ref=gravybets" target="_blank">
              <img
                v-motion-slide-visible-top
                class="w-full max-w-[450px] md:max-[420px] lg:max-w-[450px] lg:max-h-[400px] xl:max-w-[500px] xl:max-h-[470px] 2xl:max-w-[590px] 2xl:max-h-[570px]"
                src="../assets/Group.png"
                alt="gravybets"
              />
            </a>
          </div>
        </div>
      </div>
    </section>

    <section
      class="max-w-2xl md:max-w-6xl flex min-h-screen items-center justify-center text-center my-10 md:hidden"
      v-motion-slide-visible-top
    >
      <div
        class="bg-gradient-to-r from-[#090853] to-[#3c2fb6] rounded-2xl w-full mx-10 shadow-xl"
      >
        <div
          class="min-h-[40vh] bg-no-repeat bg-cover bg-center text-white"
          :style="backgroundStyle"
        >
          <div
            class="flex flex-col items-center justify-center text-center relative overflow-hidden roobet_container"
          >
            <div
              class="flex flex-col items-center justify-center text-center gap-10 py-24"
            >
              <img
                src="../assets/roobet_logo.png"
                alt="gravybets"
                class="w-full"
              />
              <div class="max-w-sm">
                <span
                  class="text-white lato-regular md:tracking-widest md:text-xl"
                  >REGISTER NOW TO GET EXCITING REWARDS EVERYDAY!</span
                >
              </div>
              <a
                href="https://roobet.com/?ref=gravybets"
                target="_blank"
                class="px-[70px] py-[14px] text-2xl bebas-neue-regular relative inline-block text-white hover:bg-yellow-50 uppercase md:tracking-wide trapezoid-btn bg-gradient-to-r from-yellow-700 to-yellow-600"
              >
                Register on Roobet.com
              </a>
            </div>
            <div class="circular-image rounded-2xl">
              <img
                src="../assets/roobet_people.png"
                alt="roobet_people"
                class="img-circular"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="hidden md:flex items-center justify-center mt-[100px]"
      v-motion-pop-visible
    >
      <div class="max-w-7xl md:mx-auto w-full my-5">
        <div class="bg-gradient-to-r from-[#090853] to-[#3c2fb6] rounded-2xl">
          <div class="w-full" :style="backgroundStyle">
            <div class="flex items-center justify-between text-center">
              <div class="w-full flex flex-col items-center justify-center">
                <img src="../assets/roobet_logo.png" alt="gravybets" />
                <span
                  class="text-white lato-regular md:tracking-widest md:text-xs my-10"
                  >REGISTER NOW TO GET EXCITING REWARDS EVERYDAY!</span
                >
                <a
                  href="https://roobet.com/?ref=gravybets"
                  target="_blank"
                  class="px-[70px] py-[14px] text-xl bebas-neue-regular relative inline-block text-white hover:bg-yellow-50 uppercase md:tracking-wide trapezoid-btn bg-gradient-to-r from-yellow-700 to-yellow-600"
                >
                  Register on Roobet.com
                </a>
              </div>
              <div class="w-full">
                <img
                  src="../assets/roobet_people.png"
                  alt="roobet_people"
                  class="rounded-left rounded-r-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <SocialSection />

    <section
      id="leaderboards"
      class="flex items-center justify-center mt-20 mb-20 py-10 scroll-mt-10"
    >
      <div class="max-w-2xl md:max-w-6xl mx-auto p-6">
        <div class="flex flex-col mx-auto justify-center items-center p-2">
          <a href="#main"
            ><img
              v-motion-pop-visible
              src="../assets/logo_name.png"
              class="w-auto h-[50px] iphonemax:h-[52px] sm:h-[55px] md:h-[60px]"
              alt="logo"
          /></a>
          <h2
            class="text-[48px] md:text-[83.29px] font-bold -mt-3 mb-6 text-center font-roboto-condensed uppercase tracking-tighter text-white bebas-neue-regular"
          >
            Leaderboards
          </h2>
        </div>
        <div v-if="loading || roobetTimer == 'Leaderboard ended'" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
          style="font-family: Russo One, sans-serif">
          {{
            loading
              ? "Calculating time ..."
              : roobetTimer
          }}
        </div>
        <div
          v-if="roobetTimer && roobetTimer != 'Leaderboard ended'"
          class="flex flex-row justify-center items-center space-x-3 -mt-10 mb-5 mx-2"
        >
          <div
            class="flex flex-col items-center justify-center text-center bg-[#2f314b] w-[70px] h-[69px] rounded-xl border-2 border-yellow-700"
          >
            <p
              class="oxanium text-4xl font-bold transition-colors bg-clip-text text-transparent bg-gradient-to-br from-yellow-800 to-yellow-500"
            >
              {{
                roobetTimer.split(" ")[0].substring(0, roobetTimer.split(" ")[0].length - 1)
              }}
            </p>
            <span class="oxanium uppercase text-white text-[14px]">Days</span>
          </div>
          <div
            class="flex flex-col items-center justify-center text-center bg-[#2f314b] w-[70px] h-[69px] rounded-xl border-2 border-yellow-700"
          >
            <p
              class="oxanium text-4xl font-bold transition-colors bg-clip-text text-transparent bg-gradient-to-br from-yellow-800 to-yellow-500"
            >
              {{
                roobetTimer.split(" ")[1].substring(0, roobetTimer.split(" ")[1].length - 1)
              }}
            </p>
            <span class="oxanium uppercase text-white text-[14px]">Hrs</span>
          </div>
          <div
            class="flex flex-col items-center justify-center text-center bg-[#2f314b] w-[70px] h-[69px] rounded-xl border-2 border-yellow-700"
          >
            <p
              class="oxanium text-4xl font-bold transition-colors bg-clip-text text-transparent bg-gradient-to-br from-yellow-800 to-yellow-500"
            >
              {{
                roobetTimer.split(" ")[2].substring(0, roobetTimer.split(" ")[2].length - 1)
              }}
            </p>
            <span class="oxanium uppercase text-white text-[14px]">Min</span>
          </div>
          <div
            class="flex flex-col items-center justify-center text-center bg-[#2f314b] w-[70px] h-[69px] rounded-xl border-2 border-yellow-700"
          >
            <p
              class="oxanium text-4xl font-bold transition-colors bg-clip-text text-transparent bg-gradient-to-br from-yellow-800 to-yellow-500"
            >
              {{
                roobetTimer.split(" ")[3].substring(0, roobetTimer.split(" ")[3].length - 1)
              }}
            </p>
            <span class="oxanium uppercase text-white text-[14px]">Sec</span>
          </div>
        </div>
      </div>
    </section>

    <div
      v-if="loading"
      class="text-center text-white text-2xl md:text-4xl mt-10 font-bold"
      style="font-family: Russo One, sans-serif"
    >
      Loading Leaderboard...
    </div>
    <div v-else>
      <LeaderboardTop3Section :leaderboardTop3Data="leaderboardTop3Data" />
      <LeaderboardTableSection :leaderboardData="leaderboardData" />
    </div>

    <section
      id="prev-leaderboards"
      class="flex items-center justify-center mt-20 mb-20 py-10"
    >
      <div class="max-w-2xl md:max-w-6xl mx-auto p-6">
        <div class="flex flex-col mx-auto justify-center items-center p-2">
          <a href="#main"
            ><img
              v-motion-pop-visible
              src="../assets/logo_name.png"
              class="w-auto h-[50px] iphonemax:h-[52px] sm:h-[55px] md:h-[60px]"
              alt="logo"
          /></a>
          <h2
            class="text-[48px] md:text-[83.29px] font-bold -mt-3 mb-6 text-center font-roboto-condensed uppercase tracking-tighter text-white bebas-neue-regular"
          >
            Previous Leaderboards
          </h2>
        </div>
      </div>
    </section>

    <div
      v-if="loadingPrev"
      class="text-center text-white text-2xl md:text-4xl myA-10 font-bold"
      style="font-family: Russo One, sans-serif"
    >
      Loading Previous Leaderboard...
    </div>
    <div v-else>
      <LeaderboardTop3Section
        v-if="leaderboardTop3DataPrev.length > 0"
        :leaderboardTop3Data="leaderboardTop3DataPrev"
      />
      <LeaderboardTableSection
        v-if="leaderboardDataPrev.length > 0"
        :leaderboardData="leaderboardDataPrev"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import roobetFrame from "../assets/roobet_frame.png";
import tableFrame from "../assets/table_frame.png";
import LeaderboardTop3Section from "@/sections/LeaderboardTop3Section.vue";
import LeaderboardTableSection from "@/sections/LeaderboardTableSection.vue";
import SocialSection from "@/sections/SocialSection.vue";

export default {
  name: "MainPage",
  components: {
    LeaderboardTableSection,
    LeaderboardTop3Section,
    SocialSection,
  },
  data() {
    return {
      leaderboardData: [],
      leaderboardTop3Data: [],
      LeaderboardList: [],
      LeaderboardListBackup: [],
      leaderboardDataPrev: [],
      leaderboardTop3DataPrev: [],
      LeaderboardListPrev: [],
      LeaderboardListBackupPrev: [],
      loading: false,
      loadingPrev: false,
      roobetTimer: "",
      notFormattedEndDate: null,
    };
  },
  mounted() {
    this.$emit("sections-ready");
  },
  methods: {
    async getLeaderboards() {
      try {
        this.loading = true;
        const res = await axios.get(process.env.VUE_APP_SERVER_URL + "api/roobet/leaderboard", {
          headers: { "x-api-key": process.env.VUE_APP_ADMIN_KEY },
        });

        this.LeaderboardList = res.data.leaderboard || [];
        this.notFormattedEndDate = res.data.endDate;
        this.LeaderboardListBackup = [...this.LeaderboardList];
        const prizes = [150, 100, 70, 50, 40, 30, 20, 20, 10, 10];

        for (let i = 0; i < this.LeaderboardList.length; i++) {
          if (this.LeaderboardListBackup[i]) {
            if (i > 2 && i < 10) {
              this.LeaderboardListBackup[i]['prizes'] = prizes[i];
              this.leaderboardData.push(this.LeaderboardListBackup[i]);
            }
            if (i < 3) {
              this.LeaderboardListBackup[i]['prizes'] = prizes[i];
              this.leaderboardTop3Data.push(this.LeaderboardListBackup[i]);
            }
          }
        }

        const crownColors = ["gold", "silver", "bronze"];
        for (let i = 0; i < this.leaderboardTop3Data.length; i++) {
          this.leaderboardTop3Data[i].crownColor = crownColors[i] || "bronze";
        }

        this.startRoobetTimer();
      } catch (error) {
        console.error('Error fetching leaderboard data:', error);
      } finally {
        this.loading = false;
      }
    },
    async getPrevLeaderboards() {
      try {
        this.loadingPrev = true;
        const res = await axios.get(
          process.env.VUE_APP_SERVER_URL + "api/roobet/leaderboard?previous=true",
          {
            headers: { "x-api-key": process.env.VUE_APP_ADMIN_KEY },
          }
        );

        this.LeaderboardListPrev = res.data.leaderboard || [];
        this.LeaderboardListBackupPrev = [...this.LeaderboardListPrev];
        const prizes = [150, 100, 70, 50, 40, 30, 20, 20, 10, 10];

        for (let i = 0; i < this.LeaderboardListPrev.length; i++) {
          if (this.LeaderboardListBackupPrev[i]) { 
            if (i > 2 && i < 10) {
              this.LeaderboardListBackupPrev[i]['prizes'] = prizes[i];
              this.leaderboardDataPrev.push(this.LeaderboardListBackupPrev[i]);
            }
            if (i < 3) {
              this.LeaderboardListBackupPrev[i]['prizes'] = prizes[i];
              this.leaderboardTop3DataPrev.push(this.LeaderboardListBackupPrev[i]);
            }
          } else {
            console.warn(`Entry at index ${i} in LeaderboardListPrev is undefined`);
          }
        }

        const crownColors = ["gold", "silver", "bronze"];
        for (let i = 0; i < this.leaderboardTop3DataPrev.length; i++) {
          this.leaderboardTop3DataPrev[i].crownColor = crownColors[i] || "bronze";
        }
      } catch (error) {
        console.error('Error fetching previous leaderboard data:', error);
      } finally {
        this.loadingPrev = false;
      }
    },
    startRoobetTimer() {
      const getNextTargetDate = () => {
        const now = moment.utc();
        let targetDate = moment(this.notFormattedEndDate)
          .utc()
          .hour(23)
          .minute(59)
          .second(59)
          .subtract(1, "days");

        if (now.isAfter(targetDate)) {
          targetDate.add(1, "M");
        }

        return targetDate;
      };

      let targetDate = getNextTargetDate();
      let roobetTimerInterval;

      const updateTimer = () => {
        const nowEst = moment.utc();
        const distance = targetDate.diff(nowEst);

        if (distance < 0) {
          this.timer = "Leaderboard ended";
          this.init();
          this.$refs.PrevLeaderBoardSection.init();
          clearInterval(roobetTimerInterval);

          targetDate = getNextTargetDate();
          this.startRoobetTimer();
        } else {
          const duration = moment.duration(distance);
          const days = Math.floor(duration.asDays());
          const hours = duration.hours();
          const minutes = duration.minutes();
          const seconds = duration.seconds();

          this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        }
      };

      updateTimer();
      roobetTimerInterval = setInterval(updateTimer, 1000);
    },
  },
  created() {
    this.getLeaderboards(), this.getPrevLeaderboards();
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${roobetFrame})`,
        borderRadius: "1rem 1rem",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
    tableBackgroundStyle() {
      return {
        backgroundImage: `url(${tableFrame})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      };
    },
  },
};
</script>

<style scope>
section {
  padding-top: 60px;
  margin-top: -60px;
}

body {
  background-color: #0b0b0c;
}
.main-bg {
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.trapezoid-btn {
  background-image: linear-gradient(
    to right,
    #83500c,
    #925f16,
    #a16f1f,
    #b07f29,
    #bf8f33
  );
  clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%); /* Trapezoid shape */
  display: inline-block;
  color: white;
  text-align: center;
  transition: background-color 0.3s;
}

.circular-image {
  width: 110%;
  overflow: hidden;
  position: relative;
}

.rounded-left {
  object-fit: cover;
  clip-path: ellipse(60% 100% at 60% 50%);
}

.circular-image .img-circular {
  width: 100%;
  height: 100%;
  clip-path: ellipse(50% 100% at 50% 100%);
  object-fit: cover;
  object-position: center bottom;
}

.roobet_container {
  border-bottom-right-radius: 1rem; /* 16px */
  border-bottom-left-radius: 1rem; /* 16px */
  border-top-left-radius: 1rem; /* 16px */
  border-top-right-radius: 1rem; /* 16px */
}
.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
