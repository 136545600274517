<template>
      <div class="bg-gradient-to-tr from-black via-yellow-700 to-yellow-800 p-[0.1rem] rounded-xl">
          <div class="bg-black text-white p-3 sm:p-6 text-center w-[180px] sm:w-64 shadow-xl relative" :style="backgroundStyle">
            <div class="absolute -top-28 left-0 w-full flex justify-center items-center z-10">
                <div class="flex flex-col items-center justify-center">
                    <img v-if="crownColor === 'gold'" src="../assets/crown_gold.png" alt="crown" class="w-16 h-16">
                    <img v-else-if="crownColor === 'silver'" src="../assets/crown_silver.png" alt="crown" class="w-16 h-16">
                    <img v-else src="../assets/crown_bronze.png" alt="crown" class="w-16 h-16">
                    <div class="bg-gradient-to-br from-yellow-500 via-yellow-800 to-yellow-200 rounded-full p-1">
                        <img src="../assets/avatar.png" alt="avatar" class="w-20 h-20 rounded-full">
                    </div>
                </div>
            </div>
          
            
            <div class="text-yellow-400 font-bold text-lg bebas-neue-regular mt-7">
                {{ username }}
            </div>

            <div class="mt-2.5 sm:mt-4 text-white font-bold text-[28px] leading-[30px] sm:text-3xl bebas-neue-regular">
                $ {{ wagered }}
            </div>
            <div class="text-yellow-500 text-base sm:text-xl inter-regular uppercase">
                WAGERED
            </div>
    
            <div class="mt-2 sm:mt-6 bg-yellow-500 text-black w-full max-w-[134px] sm:max-w-[255px] text-center py-2 sm:py-4 font-semibold trapezoid-btn bebas-neue-regular text-lg sm:text-2xl">
                $ {{ amount}}
            </div>
 
        </div>
      </div>
</template>

<script>
import topUserBgFrame from '../assets/top_user_bg_frame.png';
export default {
    name: 'FooterComponent',
    props: {
        username: String,
        wagered: null,
        amount: null,
        crownColor: null,
    },
    data() {
        return {
            navItems: [
                { id: 'home', name: 'Home' },
                { id: 'socials', name: 'Socials' },
                { id: 'leaderboards', name: 'Leaderboards' },
            ]
        };
    },
    computed: {
      backgroundStyle() {
        return {
          backgroundImage: `url(${topUserBgFrame})`,
          borderRadius: '1rem 1rem',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      },
    }
}
</script>

<style>
.trapezoid-btn {
  background-image: linear-gradient(to right, #83500c, #925f16, #a16f1f, #b07f29, #bf8f33);
  clip-path: polygon(0% 0%, 100% 0%, 85% 100%, 15% 100%); /* Trapezoid shape */
  display: inline-block;
  color: white;
  text-align: center;
  transition: background-color 0.3s;
}
</style>
