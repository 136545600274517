<template>
    <section class="mb-16 md:mb-24">
        <div class="grid grid-cols-2 gap-2.5 sm:gap-4 md:gap-[300px] w-max mx-auto" v-motion-pop-visible>
            <div class="col-span-2 flex justify-center mb-24 md:-mb-[96%]">
                <div v-for="(card, i) in maskedUsernames" :key="card.username">
                    <UserCard
                        v-if="i === 0"
                        :username="card.maskedUsername"
                        :wagered="formatNumber(card.weightedWagered)"
                        :amount="Math.floor(card.prizes)"
                        :crownColor="card.crownColor"
                    />
                </div>
            </div>
             
            <UserCard
                v-for="card in maskedUsernames.slice(1)"
                :key="card.username"
                :username="card.maskedUsername ?? 'sample'"
                :wagered="formatNumber(card.weightedWagered)"
                :amount="Math.floor(card.prizes)"
                :crownColor="card.crownColor"
            />
        </div>
    </section>
</template>
  
<script>
import UserCard from '@/cards/UserCard.vue';
export default {
    props: {
        leaderboardTop3Data: {
        type: Array,
        required: true,
      }
    },
    components: {
        UserCard
    }, 
    computed: {
      maskedUsernames() {
        return this.leaderboardTop3Data.map((user) => ({
          ...user,
          maskedUsername: this.maskText(user.username), 
        }));
      },
    },
    data() {
        return {
            cards: [
                { username: 'CHICKENWINGS123', wagered: 100000, amount: 10000, crownColor: 'gold' },
                { username: 'CHICKENWINGS123', wagered: 100000, amount: 10000, crownColor: 'silver' },
                { username: 'CHICKENWINGS123', wagered: 100000, amount: 10000, crownColor: 'bronze' },
            ]
        };
    },
    methods: {
        formatNumber(num) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(num);
        },
        maskText(username) {
        if (username.length <= 4) {
            return username[0] + "**" + username[username.length - 1];
        }

        const firstTwo = username.slice(0, 2);
        const lastTwo = username.slice(-2);
        const maskedMiddle = "*".repeat(username.length - 4);
        return `${firstTwo}${maskedMiddle}${lastTwo}`;
      },
    },
}
</script>