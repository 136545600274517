<template>
    <div class="relative">
        <nav :class="{'backdrop-blur-md bg-opacity-95': isScrolled, 'bg-[#1b1b1b]': isSidebarOpen}" class="fixed top-0 left-0 w-full px-4 py-6 md:p-2 transition-all duration-300 ease-in-out z-40">
            <div class="container mx-auto max-w-2xl md:max-w-7xl flex justify-between items-center">

                <a href="#main"><img v-motion-pop-visible src="../assets/logo_name.png" class="w-auto h-[45px] md:h-[80px]" alt="logo"></a>

                <div class="hidden md:flex space-x-4 text-white">
                    <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" :class="['block w-full text-[18px] text-left px-6 py-2 rounded mb-2 transition duration-200', { 'transition-colors bg-clip-text text-transparent bg-gradient-to-r from-yellow-800  to-yellow-500 font-medium': activeSection === item.id }]">{{ item.name }}</a>
                </div>

                <div class="md:hidden">
                <button @click="toggleSidebar" class="focus:outline-none">
                    <svg v-if="!isSidebarOpen" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="w-9 h-9">
                        <path fill-rule="evenodd" d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-9 h-9">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                </div>
            </div>
        </nav>

        <div :class="{'translate-x-0 w-[85%]': isSidebarOpen, '-translate-x-full': !isSidebarOpen}"
        class="fixed top-[92px] left-0 h-full bg-[#1b1b1b] transform transition-transform duration-300 ease-in-out z-50">
            <div class="text-white">
                <a v-for="item in navItems" :key="item.id" :href="`#${item.id}`" @click="toggleSidebar" class="block w-full text-left px-6 py-3 mb-1 transition duration-200 font-medium" :class="{'bg-[#313131]': activeSection === item.id}">
                    <span :class="{ 'bg-clip-text text-transparent bg-gradient-to-r from-yellow-800 to-yellow-500': activeSection === item.id, 'text-gray-400': activeSection !== item.id }">
                        {{ item.name }}
                    </span>
                </a>
            </div>
        </div>

        <div v-if="isSidebarOpen" @click="closeSidebar" class="fixed inset-0 bg-gray-950 bg-opacity-75 z-20 transition-opacity duration-300 ease-in-out">
        </div>
  </div>
</template>

<script>
export default {
    name: 'NavbarComponent',
    props: {
        activeSection: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isScrolled: false,
            isSidebarOpen: false,
            navItems: [
                { id: 'home', name: 'Home' },
                { id: 'socials', name: 'Socials' },
                { id: 'leaderboards', name: 'Leaderboards' },
            ]
        };
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen;

            if (this.isSidebarOpen && window.innerWidth < 768) {
                this.isScrolled = true;
            } else if (!this.isSidebarOpen && window.scrollY === 0) {
                this.isScrolled = false;
            }
        },
        closeSidebar() {
            this.isSidebarOpen = false;
        },
        handleScroll() {
            this.isScrolled = window.scrollY > 0;

            if (this.isScrolled && window.innerWidth < 768) {
                this.isScrolled = true;
            }
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>