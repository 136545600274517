<template>
    <section class="mb-[100px] md:min-h-fit flex items-center justify-center">
      <div class="md:mx-auto w-full mx-4 my-5 max-w-6xl">
        <div class="rounded-xl overflow-hidden shadow-lg drop-shadow-2xl shadow-custom" :style="tableBackgroundStyle">
          <table class="min-w-full rounded-xl overflow-hidden">
            
            <thead class="bg-black bg-opacity-50" v-motion-pop-visible>
                <tr>
                    <th v-for="header in tableHeaders" :key="header.name" 
                        class="px-2 py-6 sm:p-6 text-lg font-semibold text-white uppercase tracking-wider"
                        :class="header.textStyle">
                        {{ header.name }}
                    </th>
                </tr>
            </thead>
            <tbody class="uppercase bg-black bg-opacity-50 overflow-y-auto">
              <tr v-for="(user, index) in maskedUsernames" :key="index" class="transition-colors" v-motion-pop-visible>
                <td class="p-2 pl-3 sm:p-6 sm:pl-8 whitespace-nowrap text-base text-left text-white">
                    <span class="text-white">{{ formatRank(index + 4) }}</span>
                </td>
                <td class="whitespace-nowrap text-base">
                  <div class="flex items-center justify-center mx-auto w-fit rounded-full p-1 sm:p-2">
                    <div class="w-6 h-6 rounded-full overflow-hidden sm:mr-2 hidden md:block">
                      <img src="../assets/avatar.png" alt="avatar" class="w-full h-full object-cover" />
                    </div>
                    <p class="text-base text-white sm:w-fit overflow-hidden text-ellipsis whitespace-nowrap w-28">{{ user.maskedUsername }}</p>
                  </div>
                </td>
                <td class="p-3 sm:p-6 whitespace-nowrap text-base text-center text-white flex items-center justify-center">
                  &dollar;
                  <p class="text-base text-white sm:w-fit overflow-hidden text-ellipsis whitespace-nowrap w-20">{{ formatNumber(user.weightedWagered) }}</p>
                </td>
                <td class="whitespace-nowrap text-base">
                  <div class="flex items-center justify-center w-fit rounded-full p-2 mx-auto">
                    <span class="text-base text-yellow-500 mr-1">&dollar;</span>
                    <span class="text-base text-white">{{ formatNumber(user.prizes) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </template>

  <script>
  export default {
    props: {
      leaderboardData: {
        type: Array,
        required: true,
      }
    },
    computed: {
      maskedUsernames() {
        return this.leaderboardData.map((user) => ({
          ...user,
          maskedUsername: this.maskText(user.username), 
        }));
      },
    },
    methods: {
      maskText(username) {
        if (username.length <= 4) {
            return username[0] + "**" + username[username.length - 1];
        }

        const firstTwo = username.slice(0, 2);
        const lastTwo = username.slice(-2);
        const maskedMiddle = "*".repeat(username.length - 4);
        return `${firstTwo}${maskedMiddle}${lastTwo}`;
      },
    },
  }
  </script>
  
  <script setup>
  import { ref, computed } from 'vue';
  import tableFrame from '../assets/table_frame.png';
  
  const tableHeaders = ref([{ name: 'Rank', textStyle: 'text-left rounded-tl-xl pl-2 sm:pl-8' }, { name: 'User', textStyle: 'text-center rounded-tr-xl' }, { name: 'Wagered', textStyle: 'text-center' }, { name: 'Prize', textStyle: 'text-center rounded-bl-xl' }]);

  const tableBackgroundStyle = computed(() => ({
    backgroundImage: `url(${tableFrame})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 5,
  }));
  
  const formatRank = (rank) => {
    const suffixes = ['th', 'st', 'nd', 'rd', ''];
    const suffix = (rank % 100 > 10 && rank % 100 < 20) ? suffixes[0] : suffixes[rank % 10] || suffixes[0];
    return `${rank}${suffix}`;
  };
  
  const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(num);
};
  </script>
  
<style scoped>
.shadow-custom {
  box-shadow: 0 4px 6px -1px rgba(246, 184, 59, 0.5), 0 2px 4px -1px rgba(59, 130, 246, 0.06);
}
</style>