<template>
    <a :href="goToUrl" target="_blank">
        <div class="element">
            <div class="bg-gradient-to-t p-[0.1rem] rounded-xl m-3.5" :class="classes">
                <div class="bg-black bg-opacity-75 rounded-xl" :style="backgroundStyle">
                    <div class="flex flex-col items-center justify-around gap-10 px-14 py-12">
                        <img v-if="iconName === 'youtube'" src="../assets/icon_youtube.png" alt="crown" class="w-24 h-24">
                        <img v-else-if="iconName === 'discord'" src="../assets/icon_discord.png" alt="crown" class="w-24 h-24">
                        <img v-else-if="iconName === 'instagram'" src="../assets/icon_instagram.png" alt="crown" class="w-24 h-24">
                        <img v-else-if="iconName === 'tiktok'" src="../assets/icon_tiktok.png" alt="crown" class="w-24 h-24">
                        <img v-else src="../assets/icon_twitter.png" alt="crown" class="w-24 h-24">
                        <div class="inter-regular text-white text-md">
                              {{ name }}
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
import socialBgFrame from '../assets/socials_bg_frame.png';
export default {
  name: 'FooterComponent',
  props: {
      name: String,
      iconName: String,
      classes: String,
      goToUrl: String,
  },
  data() {
      return {
          navItems: [
              { id: 'home', name: 'Home' },
              { id: 'socials', name: 'Socials' },
              { id: 'leaderboards', name: 'Leaderboards' },
          ]
      };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${socialBgFrame})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    },
  }
}
</script>

<style>
.element {
  border: 0.5px solid;
  border-image: linear-gradient(45deg, #4d3e009b, #ffb70078, #4d3e009b) 1 roud;
  border-radius: 1rem;
}
</style>
