<template>
    <section id="socials" class="mt-24 scroll-mt-10">
        <div class="flex flex-col mx-auto justify-center items-center mb-2">
              <a href="#main"><img v-motion-pop-visible src="../assets/logo_name.png" class="w-auto h-[50px] iphonemax:h-[52px] sm:h-[55px] md:h-[60px]" alt="logo"></a>
              <h2 class="text-[60px] md:text-[83.29px] font-bold -mt-3 mb-6 text-center font-roboto-condensed uppercase tracking-tighter text-white bebas-neue-regular">Socials</h2>
        </div>
        <div class="flex flex-wrap justify-center items-center gap-5">
            <SocialIconCard
                v-for="card in cards"
                :key="card.name"
                :name="card.name"
                :iconName="card.iconName"
                :classes="card.classes"
                :goToUrl="card.goToUrl"
                v-motion-pop-visible
            />
        </div>
    </section>
</template>
  
<script>
import SocialIconCard from '@/cards/SocialIconCard.vue';
export default {
    components: {
        SocialIconCard
    },
    data() {
        return {
            cards: [
                { name: 'Youtube', iconName: 'youtube', classes: 'from-black via-red-800 to-red-500', goToUrl: 'https://www.youtube.com/@GravyBets ' },
                { name: 'Discord', iconName: 'discord', classes: 'from-black via-blue-800 to-blue-500', goToUrl: 'https://discord.gg/EPQh3rPhme' },
                { name: 'Instagram', iconName: 'instagram', classes: 'from-black via-yellow-700 to-purple-500', goToUrl: 'https://www.instagram.com/gravy_bets/' },
                { name: 'Tiktok', iconName: 'tiktok', classes: 'from-black via-red-500 to-cyan-500', goToUrl: 'https://www.tiktok.com/@gravy_bets' },
                { name: 'Twitter / X', iconName: 'twitter', classes: 'from-black via-cyan-200 to-blue-300', goToUrl: 'https://x.com/GravyBet' },
            ]
        };
    },
}
</script>