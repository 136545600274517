<template>
    <div>
        <NavbarComponent :activeSection="activeSection" />
        <router-view @sections-ready="setupIntersectionObserver"></router-view>
        <FooterComponent :activeSection="activeSection" />
    </div>
</template>

<script>
import axios from 'axios';
import NavbarComponent from '../components/NavbarComponent.vue';
import FooterComponent from '../components/FooterComponent.vue';

export default {
    components: {
        NavbarComponent,
        FooterComponent
    },
    data() {
        return {
            activeSection: 'home',
            observer: null
        };
    },
    methods: {
        setupIntersectionObserver() {
            this.observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    this.activeSection = entry.target.id;
                }
                });
            }, { threshold: 0.5 });

            ['home', 'socials', 'leaderboards'].forEach((id) => {
                const element = document.getElementById(id);
                if (element) this.observer.observe(element);
            });
        }
    },
    mounted(){
        const code = this.$route.query.code;
		if(code != null){
			this.loading = true
			let formData = {
				code: code
			};
			axios.post(process.env.VUE_APP_SERVER_URL + 'auth/twitchLogin', formData, { headers: { 'Content-Type': 'application/json'}} ).then((res)=>{
				if(res){
					localStorage.setItem("oeg-access-token", res.data.accessToken)
					this.$store.commit('UPDATE_USER', res.data.accessToken)
					this.loading = false
					// this.$router.replace("/profile")
				}
			})
		}
    },
    beforeUnmount() {
        if (this.observer) {
            ['home', 'socials', 'leaderboards'].forEach((id) => {
                const element = document.getElementById(id);
                if (element) this.observer.unobserve(element);
            });
        }
    }
}
</script>